import {
  //   disp_spinner_button,
  //   disp_flashing,
  disp_open_file
} from "../common/ui"
$(function () {
  if ($(".invoice.new").length < 1) {
    return;
  }
  disp_open_file("#invoice_pdfs", "#select_file_name")
})